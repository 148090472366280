<template>
<add-adudition></add-adudition>
</template>

<script>
import addAdudition from "@/components/frontbusiness/auditionManage/addAdudition";
export default {
  name: 'tryLessonDetail',
  components: {addAdudition},
}
</script>

<style scoped>

</style>
